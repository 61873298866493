import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="trippe.io" />
    <div class="vh-50 dt w-100 bg-dark-red">
      <div class="dtc v-btm pa4">
        <h1 class="f1 f2-m f-subheadline-l fw6 white tc">trippe.io</h1>
        <h5 class="red">
          * portland based // web development // product management // ecommerce
          // shopify plus // web-apps // static-sites // javascript // node //
          express // php // mysql // gatsby // vue.js
        </h5>
      </div>
    </div>
    <div class="vh-50 dt w-100 bg-dark-red">
      <div class="dtc pa3 v-btm white tl">
        <h6>
          1125 se division st.<br></br>suite 203
          <br></br>portland, or
          <br></br>97202
        </h6>
        <h6>
          <a class="link underline-hover white" href="mailto:info@trippe.io">
            info@trippe.io
          </a>
        </h6>
      </div>
    </div>

    <div class="vh-50 dt w-100 bg-dark-blue">
      <div class="dtc v-btm pa4">
        <h1 class="f1 f2-m f-subheadline-l fw6 white tc">trippe.io</h1>
        <h5 class="blue">
          portland based // web development // product management // ecommerce
          // shopify plus // web-apps // static-sites // javascript // node //
          express // php // mysql // gatsby // vue.js
        </h5>
      </div>
    </div>
    <div class="vh-50 dt w-100 bg-dark-blue">
      <div class="dtc pa3 v-btm white tl">
        <h6>
          1125 se division st.<br></br>suite 203
          <br></br>portland, or
          <br></br>97202
        </h6>
        <h6>
          <a class="link underline-hover white" href="mailto:info@trippe.io">
            info@trippe.io
          </a>
        </h6>
      </div>
    </div>
  </Layout>
)

export default IndexPage
